export const emojiUrl = 'static/dist/img/face/'
export const emojiMap = {
  '[100分]': '100分.png',
  '[爱心]': '爱心.png',
  '[拜托]': '拜托.png',
  '[不看]': '不看.png',
  '[不说]': '不说.png',
  '[不听]': '不听.png',
  '[吃惊]': '吃惊.png',
  '[蹙眉]': '蹙眉.png',
  '[大哭]': '大哭.png',
  '[鼓掌]': '鼓掌.png',
  '[鬼脸]': '鬼脸.png',
  '[好的]': '好的.png',
  '[花]': '花.png',
  '[坏笑]': '坏笑.png',
  '[加油]': '加油.png',
  '[禁止]': '禁止.png',
  '[举手]': '举手.png',
  '[可爱]': '可爱.png',
  '[酷]': '酷.png',
  '[困]': '困.png',
  '[亲亲]': '亲亲.png',
  '[庆祝]': '庆祝.png',
  '[拳头]': '拳头.png',
  '[伤心]': '伤心.png',
  '[思考]': '思考.png',
  '[叹气]': '叹气.png',
  '[调皮]': '调皮.png',
  '[偷笑]': '偷笑.png',
  '[吐舌头]': '吐舌头.png',
  '[微笑]': '微笑.png',
  '[握手]': '握手.png',
  '[捂脸]': '捂脸.png',
  '[喜欢]': '喜欢.png',
  '[写字]': '写字.png',
  '[嘘]': '嘘.png',
  '[耶]': '耶.png',
  '[炸弹]': '炸弹.png',
  '[真棒]': '真棒.png',
}
export const emojiName = [
  '[100分]',
  '[爱心]',
  '[拜托]',
  '[不看]',
  '[不说]',
  '[不听]',
  '[吃惊]',
  '[蹙眉]',
  '[大哭]',
  '[鼓掌]',
  '[鬼脸]',
  '[好的]',
  '[花]',
  '[坏笑]',
  '[加油]',
  '[禁止]',
  '[举手]',
  '[可爱]',
  '[酷]',
  '[困]',
  '[亲亲]',
  '[庆祝]',
  '[拳头]',
  '[伤心]',
  '[思考]',
  '[叹气]',
  '[调皮]',
  '[偷笑]',
  '[吐舌头]',
  '[微笑]',
  '[握手]',
  '[捂脸]',
  '[喜欢]',
  '[写字]',
  '[嘘]',
  '[耶]',
  '[炸弹]',
  '[真棒]',
]
