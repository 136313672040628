import axios from 'axios'
const CancelToken = axios.CancelToken;
const XLSX = require("xlsx");


export const uploadFileTask = (url, formData, call, cancelFunc) => {
    return axios.post(url, formData, {
        timeout: 1000 * 60 * 60 * 24,
        headers: { 'Content-Type': 'multipart/form-data' },
        cancelToken: new CancelToken(function executor(cancel) {
            cancelFunc(cancel)
        }),
        onUploadProgress: function (e) {
            if (e.lengthComputable) {
                call(Math.round(e.loaded / e.total * 100))
            }
        }
    })
}
export const computeFileSize = () => {
   
}
/* 获取字节数 */
export const getBytesLength = (a) => {
    var b = 0;
    for (var i = a.length; i-- > 0;) {
        // 一个中文按3个字节
        if (a[i].charCodeAt() > 255) {
            b += 3;
        } else {
            b++;
        }
    }
    return b;
}

export const browerDownloadFile = ({ fileUrl, fileName }) => {
    let httpRequest = new XMLHttpRequest();
    //指定响应类型，这决定了浏览器对返回内容的解析方式，设置为空或者text会作为字符解析、json会作为json解析，blob和arraybuffer会作为字节流解析
    httpRequest.responseType = 'arraybuffer';
    httpRequest.open('GET', fileUrl.replace(/^http:/, 'https:') + '?response-content-type=application/octet-stream', true);
    httpRequest.onload = () => {
        if (httpRequest.readyState == 4 && httpRequest.status == 200) {
            //只有responseType为空或者text，才会使用responseText获取内容，其他情况                        httpRequest.response就是你需要的不含http头的返回内容
            let content = httpRequest.response;
            let link = document.createElement('a');
            link.download = fileName;

            link.style.display = 'none';
            let blob = new Blob([content]);
            //创建指向内存中字节流的链接
            link.href = URL.createObjectURL(blob);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };
    httpRequest.send();
    // 浏览器支持fetch则用blob下载，避免浏览器点击a标签，跳转到新页面预览的行为
    
}


/* 数据导出ecxcel [[111, 222, 333], [444, 555, 666]] */
export const dataToExcel = (data, fileName) => {
    const ws_name = "SheetJS";
    const wb = XLSX.utils.book_new(), ws = XLSX.utils.aoa_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, ws_name);
    XLSX.writeFile(wb, fileName);
}

/* table元素导出excel */
export const tableToExcel = (tableDom, fileName) => {
    const wb = XLSX.utils.table_to_book(tableDom, { sheet: "Sheet JS" });
    XLSX.writeFile(wb, fileName);
}