import TIM from 'tim-js-sdk'
export const formatTree = (obj, options = { id, parentId, originId }) => {
    let copyedObj = JSON.parse(JSON.stringify(obj))  //深拷贝源数据
    console.log(options['id'], options['parentId'])
    return copyedObj.filter(parent => {
        let findChildren = copyedObj.filter(child => {
            return parent[options['id']] === child[options['parentId']]
        })
        findChildren.length > 0 ? parent.children = findChildren : parent.children = []
        return parent[options['parentId']] == (options['originId'] === undefined ? 0 : options['originId'])  //返回顶层，依据实际情况判断这里的返回值
    })
}

export const formatTree_fix = (obj, options = { id, parentId, originIdList }) => {
    let copyedObj = JSON.parse(JSON.stringify(obj))  //深拷贝源数据
    console.log(options['id'], options['parentId'])
    return copyedObj.filter(parent => {
        let findChildren = copyedObj.filter(child => {
            return parent[options['id']] === child[options['parentId']]
        })
        findChildren.length > 0 ? parent.children = findChildren : parent.children = []
        return options['originIdList'].includes(parent[options['parentId']])
    })
}
export function translateGroupSystemNotice(message) {
    const groupName = message.payload.groupProfile.name || message.payload.groupProfile.groupID
    switch (message.payload.operationType) {
        case 1:
            return `${message.payload.operatorID} 申请加入群组：${groupName}`
        case 2:
            return `成功加入群组：${groupName}`
        case 3:
            return `申请加入群组：${groupName}被拒绝`
        case 4:
            return `你被管理员${message.payload.operatorID}踢出群组：${groupName}`
        case 5:
            return `群：${groupName} 已被${message.payload.operatorID}解散`
        case 6:
            return `${message.payload.operatorID}创建群：${groupName}`
        case 7:
            return `${message.payload.operatorID}邀请你加群：${groupName}`
        case 8:
            return `你退出群组：${groupName}`
        case 9:
            return `你被${message.payload.operatorID}设置为群：${groupName}的管理员`
        case 10:
            return `你被${message.payload.operatorID}撤销群：${groupName}的管理员身份`
        case 255:
            return '自定义群系统通知: ' + message.payload.userDefinedField
    }
}
export function filterCallingMessage(currentMessageList) {
    currentMessageList.forEach((item) => {
        if (item.callType) {   // 对于自己伪造的消息不需要解析
            return
        }
        if (item.type === TIM.TYPES.MSG_CUSTOM) {
            let payloadData = {}
            try {
                payloadData = JSON.parse(item.payload.data)
            } catch (e) {
                payloadData = {}
            }
            if (payloadData.businessID === 1) {
                if (item.conversationType === TIM.TYPES.CONV_GROUP) {
                    if (payloadData.actionType === 5) {
                        item.nick = payloadData.inviteeList ? payloadData.inviteeList.join(',') : item.from
                    }
                    let _text = '' //window.trtcCalling.extractCallingInfoFromMessage(item)
                    let group_text = `${_text}`
                    item.type = TIM.TYPES.MSG_GRP_TIP
                    let customData = {
                        operationType: 256,
                        text: group_text,
                        userIDList: []
                    }
                    item.payload = customData//JSON.stringify(customData)
                }
                if (item.conversationType === TIM.TYPES.CONV_C2C) {
                    let c2c_text = '' //window.trtcCalling.extractCallingInfoFromMessage(item)
                    let customData = {
                        text: c2c_text
                    }
                    item.payload = customData//JSON.stringify(customData)
                }
            }
        }

    })
}

